/* eslint-disable func-names */
const debounce = (func, delay) => {
  let debounceTimer;

  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

module.exports = {
  debounce,
};
