import { useEffect, useState } from 'react';

import useClientFacetedState from '../use-client-faceted-state';
import buildInitialState from './build-initial-state';

// Return the selected values from the server until the client state
// and handlers are built, otherwise the filters won't show a value
// while the javascript is loading, causing an undesired flashing effect
// in the UI.
const useClientFacetedFilters = ({ appliedFilters, availableFilters, facetedFilters }) => {
  const [isMounted, setIsMounted] = useState(false);
  const facetedState = useClientFacetedState({ appliedFilters, availableFilters, facetedFilters });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? facetedState : buildInitialState(facetedFilters);
};

export default useClientFacetedFilters;
