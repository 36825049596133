/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { getAppliedFilters } from '../../../context/filters/helpers';
import useFacetedFilters from '../../../../hooks/use-faceted-filters';
import { getSwitchFilterLabel, getSwitchFilterValue, isSwitchFilterAvailable } from '../../helpers/switch-filter';
import {
  addDefaults,
  getDefaultState,
  getSelectedFiltersWithDefaults,
  getDefaultLocationFilterState,
} from './defaults';
import { merge, mergeSelectedFilterCurried, mergeCurried } from './merge-filters';

const setHandler = (filter, handleChange) => ({
  ...filter,
  handleChange,
});

const getAutosuggestEnabledSites = (facetedFilters) => facetedFilters.autosuggest_enabled_sites;

const useClientFacetedState = ({ appliedFilters, availableFilters, facetedFilters }) => {
  const [clientFacetedFilters, setClientFacetedFilters] = useState({ ...facetedFilters });
  const [clientAppliedFilters, setClientAppliedFilters] = useState(() => getAppliedFilters(appliedFilters));

  const {
    dropdownFilters: [operation, propertyType],
    switchFilter,
    locationFilter,
    onFilterChange,
  } = useFacetedFilters({
    facetedFilters: clientFacetedFilters,
    appliedFilters: clientAppliedFilters,
    autosuggestEnabledSites: getAutosuggestEnabledSites(facetedFilters),
  });

  const [facetedFiltersById, setFacetedFiltersById] = useState(() =>
    getDefaultState({
      operation,
      propertyType,
      operationSubType: switchFilter,
      appliedFilters: clientAppliedFilters,
    }),
  );

  const operationSubType = {
    name: getSwitchFilterLabel(facetedFilters),
    selectedOption: !!switchFilter?.selectedOption,
    disabled: !isSwitchFilterAvailable(availableFilters, switchFilter, { strict: false }),
  };

  const handleFilterChange = (changes) => {
    setFacetedFiltersById(mergeCurried(changes));
    setClientAppliedFilters(mergeCurried(changes));
  };

  const handleFilterChangeWithDefaults = (changes) => {
    const changesWithDefaults = addDefaults(facetedFilters, changes);
    const selectedFilters = getSelectedFiltersWithDefaults(clientFacetedFilters, changesWithDefaults);

    handleFilterChange(changesWithDefaults);
    setClientFacetedFilters(mergeSelectedFilterCurried(selectedFilters));
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    const filterValue = checked ? getSwitchFilterValue(switchFilter) : null;

    setClientFacetedFilters(mergeSelectedFilterCurried({ only_new: checked }));
    onFilterChange(handleFilterChange, switchFilter?.id)(null, filterValue);
  };

  const handleLocationChange = ({ id, name, tree }) => {
    setClientFacetedFilters(mergeSelectedFilterCurried({ location: { id, name } }));
    handleFilterChange(merge(tree, getDefaultLocationFilterState()));
  };

  useEffect(() => {
    setClientAppliedFilters((prevState) => addDefaults(facetedFilters, prevState));
  }, []);

  return {
    state: facetedFiltersById,
    filters: {
      location: setHandler(locationFilter, handleLocationChange),
      operation: setHandler(operation, onFilterChange(handleFilterChangeWithDefaults, operation.id)),
      operationSubType: setHandler(operationSubType, handleCheckboxChange),
      propertyType: setHandler(propertyType, onFilterChange(handleFilterChangeWithDefaults, propertyType.id)),
    },
  };
};

export default useClientFacetedState;
