import React from 'react';

import FacetedSearch from 'vis-faceted-search/lib/Desktop';

import useVisFacetedSearch from './hooks/use-vis-faceted-search';

const VisFacetedSearchDesktop = () => {
  const { renderConfig, initialState, callToAction, onAction } = useVisFacetedSearch();

  return (
    <FacetedSearch
      renderConfig={renderConfig}
      initialState={initialState}
      callToAction={callToAction}
      onAction={onAction}
    />
  );
};

export default VisFacetedSearchDesktop;
