import { FILTERS } from '../../../../constants';

const {
  IDS: { OPERATION, PROPERTY_TYPE, OPERATION_SUBTYPE, STATE, CITY, NEIGHBORHOOD },
} = FILTERS;
const isSameValue =
  (expected) =>
  ({ value }) =>
    value === expected;

const getDefaultOperation = ([defaultOperation]) => defaultOperation;

const getDefaultPropertyType = (availableFilters, operationId) => {
  const currentOperation = availableFilters.find(isSameValue(operationId)) || getDefaultOperation(availableFilters);
  const [defaultPropertyType] = currentOperation.contains;

  return defaultPropertyType;
};

const getOperation = (availableFilters, changes) => {
  const defaultOperation = getDefaultOperation(availableFilters);

  return changes[OPERATION] || defaultOperation.value;
};

const getPropertyType = (availableFilters, changes, operationId) => {
  const defaultPropertyType = getDefaultPropertyType(availableFilters, operationId);

  return changes[PROPERTY_TYPE] || defaultPropertyType.value;
};

const addDefaults = (facetedFilters, changes) => {
  const { available_filters: availableFilters } = facetedFilters;
  const operationId = getOperation(availableFilters, changes);
  const propertyTypeId = getPropertyType(availableFilters, changes, operationId);

  return {
    ...changes,
    [OPERATION]: operationId,
    [PROPERTY_TYPE]: propertyTypeId,
  };
};

const getSelectedFiltersWithDefaults = (facetedFilters, changes) => {
  const { available_filters: availableFilters, selected_filter: selectedFilters } = facetedFilters;
  const operation = availableFilters.find(isSameValue(changes[OPERATION]));
  const property = operation.contains.find(isSameValue(changes[PROPERTY_TYPE]));

  return {
    operation: { ...selectedFilters.operation, name: operation.name, value: operation.value },
    property: { ...selectedFilters.property, name: property.name, value: property.value },
  };
};

const getDefaultState = ({ operation, propertyType, operationSubType, appliedFilters }) => ({
  [OPERATION]: operation.selectedOption,
  [PROPERTY_TYPE]: propertyType.selectedOption,
  [OPERATION_SUBTYPE]: operationSubType?.selectedOption || null,
  [STATE]: appliedFilters[STATE] || null,
  [CITY]: appliedFilters[CITY] || null,
  [NEIGHBORHOOD]: appliedFilters[NEIGHBORHOOD] || null,
});

const getDefaultLocationFilterState = () => ({
  [STATE]: null,
  [CITY]: null,
  [NEIGHBORHOOD]: null,
});

export { addDefaults, getDefaultState, getDefaultLocationFilterState, getSelectedFiltersWithDefaults };
