import React from 'react';
import { func, shape, string } from 'prop-types';

import Button from '@andes/button';
import Checkbox from '@andes/checkbox';
import Dropdown, { DropdownItem } from '@andes/dropdown';

import { BASE_FILTER_SHAPE } from './constants';
import Autocomplete from './autocomplete';

const namespace = 'ui-search-faceted-search';

const FacetedUIDesktop = ({ operation, propertyType, location, operationSubType, action }) => (
  <div className={namespace}>
    <div className={`${namespace}--searchbox-dropdown`}>
      <Dropdown
        data-testid="operation-select"
        label={operation.name}
        value={operation.selectedOption}
        size="small"
        className="operation"
        menuAlignment="bottom"
        onChange={operation.handleChange}
      >
        {operation.options.map(({ value, name }) => (
          <DropdownItem value={value} key={value} title={name} />
        ))}
      </Dropdown>
    </div>
    <div className={`${namespace}--searchbox-dropdown`}>
      <Dropdown
        data-testid="property-select"
        label={propertyType.name}
        value={propertyType.selectedOption}
        size="small"
        className="property"
        menuAlignment="bottom"
        onChange={propertyType.handleChange}
      >
        {propertyType.options.map(({ value, name }) => (
          <DropdownItem value={value} key={value} title={name} />
        ))}
      </Dropdown>
    </div>
    <div className={`${namespace}--searchbox-autocomplete`}>
      <Autocomplete
        placeholderValue={location.placeholder}
        startedValue={location.name || ''}
        startedId={location.id}
        onAutocompleteValueBus={location.handleChange}
        onAutocompleteSubmitHandler={action.handleSubmit}
        key={location.key}
        desktop
      />
    </div>
    <div className={`${namespace}--searchbox-checkbox`}>
      <Checkbox
        className={`${namespace}__item-checkbox`}
        label={operationSubType.name}
        checked={!!operationSubType.selectedOption}
        disabled={operationSubType.disabled}
        onChange={operationSubType.handleChange}
      />
    </div>
    <div className={`${namespace}--searchbox-button`}>
      <Button onClick={action.handleSubmit}>{action.label}</Button>
    </div>
  </div>
);

FacetedUIDesktop.propTypes = {
  action: shape({
    label: string.isRequired,
    handleSubmit: func.isRequired,
  }).isRequired,
  location: shape({ ...BASE_FILTER_SHAPE, key: string.isRequired, placeholder: string }).isRequired,
  operation: shape(BASE_FILTER_SHAPE).isRequired,
  operationSubType: shape(BASE_FILTER_SHAPE).isRequired,
  propertyType: shape(BASE_FILTER_SHAPE).isRequired,
};

export default FacetedUIDesktop;
