import { func, string } from 'prop-types';

const BASE_FILTER_SHAPE = {
  handleChange: func.isRequired,
  id: string.isRequired,
  name: string.isRequired,
  selectedOption: string,
};

export { BASE_FILTER_SHAPE };
