/**
 * Module dependencies
 */
import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';
import { LazyHydrate as Hydrator } from 'nordic/lazy';

import Search from './main';
import SideBar from '../../../../../components/sidebar/sidebar.desktop';
import Pagination from '../../../../../components/pagination/pagination.desktop';
import Billboard from '../../../../../components/billboard/chunk-billboard.desktop';
import ViewOptionsContainer from '../../../../../components/sidebar/components/view-options/view-options.desktop';
import Header from '../../../../../components/header/chunk-header.desktop';
import LayoutManagerContainer from '../../../../../components/layout/manager.desktop';
import SnackBar from '../../../../../components/snackbar/container';
import FacetedSearch from '../../../../../components/search/faceted.desktop';
import Exhibitor from '../../../../../components/header/exhibitor/chunk-exhibitor-header.desktop.pi';
import Carousel from '../../../../../components/carousel-search/chunk-carousel.desktop';
import { LAYOUTS } from '../../../../../constants';
import { HEADER_TYPES } from '../../../../../components/header/constants';
import { MAIN_VARIANTS } from './constants';

const namespace = 'ui-search-main';
const sectionNamespace = 'ui-search-results';
const { TYPE_MAP } = LAYOUTS;

/**
 * View Component
 */

const getMainVariant = ({ headerType }) => {
  switch (headerType) {
    case HEADER_TYPES.OFFICIAL_STORE:
      return MAIN_VARIANTS.OFFICIAL_STORE;
    case HEADER_TYPES.DEAL:
      return MAIN_VARIANTS.DEALS;
    case HEADER_TYPES.EXHIBITOR:
      return MAIN_VARIANTS.EXHIBITOR;

    default:
      return null;
  }
};

const getSectionVariationClass = (initialState) => {
  const isMap = initialState.layout_options.current === TYPE_MAP;

  return `${sectionNamespace}${isMap ? '--map' : ''}`;
};

const SearchDesktopPI = ({ baseURL, ...rest }) => {
  const { initialState } = rest;
  const headerType = initialState.header ? initialState.header.type : false;

  return (
    <Search baseURL={baseURL} visualId="pi" groupSite=".pi" platformId="PIN" {...rest}>
      <Hydrator whenVisible>
        {headerType === HEADER_TYPES.DEAL || headerType === HEADER_TYPES.OFFICIAL_STORE ? <Header /> : <Exhibitor />}
      </Hydrator>
      <div className={classnames(namespace, headerType && `${namespace}--${getMainVariant({ headerType })}`)}>
        <SideBar />
        <section className={getSectionVariationClass(initialState)}>
          <ViewOptionsContainer key={`${namespace}-sort-filter`} className={`${namespace}-pi-view-options`} />
          {headerType !== HEADER_TYPES.OFFICIAL_STORE && <FacetedSearch />}
          <Hydrator whenVisible>
            <Carousel />
          </Hydrator>
          <Hydrator whenVisible>
            <Billboard />
          </Hydrator>
          <LayoutManagerContainer />
          <Pagination />
          <SnackBar />
        </section>
      </div>
    </Search>
  );
};

SearchDesktopPI.propTypes = {
  baseURL: string.isRequired,
};

/**
 * Inject i18n context as props into View.
 */
export default SearchDesktopPI;
