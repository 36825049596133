/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from 'react';
import { string, arrayOf, shape, bool, func } from 'prop-types';

import { findAll } from 'highlight-words-core';
import TextField from '@andes/textfield';

import useAutocompleteUrl from '../../hooks/use-autocomplete';
import { debounce } from '../../lib/utils';

const namespace = 'ui-search-autocomplete';

const Autocomplete = ({
  startedValue,
  startedId,
  placeholderValue,
  onAutocompleteValueBus,
  defaultSuggestion,
  desktop,
  onAutocompleteSubmitHandler,
}) => {
  const [isLoading, doAutocomplete] = useAutocompleteUrl();
  const [suggestions, setSuggestions] = useState(defaultSuggestion);
  const [initialValue, setValue] = useState(startedValue);
  const onSetSuggestions = (suggestion) => {
    setSuggestions(suggestion);
  };
  const suggestionItem = useMemo(() => ({}), []);
  const [resultsNavPosition, setResultsNavPosition] = useState(-1);

  const createReferencesToSuggestionList = (index) => {
    suggestionItem[index] = React.createRef();

    return suggestionItem[index];
  };

  const checkListLimits = (direction) => {
    const total = suggestions.length;
    const current = resultsNavPosition;

    if (direction === 'up' && current === 0) {
      return false;
    }

    return !(direction === 'down' && current === total - 1);
  };
  const searchNavigation = (event) => {
    const prev = resultsNavPosition - 1;
    const next = resultsNavPosition + 1;

    event.preventDefault();

    switch (event.key) {
      case 'Enter':
        if (suggestions.length === 0) {
          onAutocompleteSubmitHandler();
        } else {
          suggestionItem[resultsNavPosition].current.click();
          setSuggestions([]);
        }

        break;
      case 'Escape':
        setSuggestions([]);

        break;
      case 'ArrowUp':
        if (checkListLimits('up')) {
          if (suggestionItem[prev].current.nextSibling) {
            suggestionItem[prev].current.nextSibling.classList.remove('active');
          }

          suggestionItem[prev].current.classList.add('active');
          setResultsNavPosition(prev);
        }

        break;
      case 'ArrowDown':
        if (checkListLimits('down')) {
          if (suggestionItem[next].current.previousSibling) {
            suggestionItem[next].current.previousSibling.classList.remove('active');
          }

          suggestionItem[next].current.classList.add('active');
          setResultsNavPosition(next);
        }

        break;

      default:
        break;
    }
  };

  const closeAutocomplete = () => {
    onAutocompleteValueBus();
  };

  const getSuggestions = debounce((value) => {
    if (isLoading) {
      setSuggestions([]);
    }

    doAutocomplete(value)
      .then((response) => {
        const filteredResponse = response.filter((it) => it.id !== startedId);

        onSetSuggestions(filteredResponse);
        setValue(value);
        setResultsNavPosition(-1);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, 800);

  const getSuggestionsHandler = (value) => (value === '' ? setSuggestions([]) : getSuggestions(value));

  const onSelectItem = (selected) => {
    onAutocompleteValueBus(selected);
  };

  const getHighlightedText = (textToHighlight, higlight) => {
    const value = higlight.replace(/[{()}]/g, '');
    const searchWords = value.split(' ');
    const chunks = findAll({
      searchWords,
      textToHighlight,
    });
    const highlightedText = chunks
      .map((chunk) => {
        const { end, highlight, start } = chunk;

        let text = textToHighlight.substr(start, end - start);

        if (highlight) {
          text = `<strong>${text}</strong>`;
        }

        return text;
      })
      .join('');

    return `<span>${highlightedText}</span>`;
  };

  return (
    <div className={`${namespace}__container ${desktop && 'desktop'}`} data-testid="autocomplete-container">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        tabIndex={0}
        data-testid="close-autocomplete-modal"
        className={`${namespace}__close`}
        onClick={() => {
          closeAutocomplete();
        }}
      />
      <TextField
        data-testid="autocomplete-textfield"
        placeholder={placeholderValue}
        defaultValue={initialValue}
        onChange={(e) => {
          getSuggestionsHandler(e.target.value);
        }}
        onKeyUp={(e) => {
          searchNavigation(e);
        }}
      />
      <ul className={`${namespace}__list`} data-testid="suggestions">
        {suggestions.map((suggestion, index) => (
          <li
            ref={createReferencesToSuggestionList(index)}
            className={`${namespace}__list-item`}
            onClick={() => {
              onSelectItem(suggestion);
            }}
            key={suggestion.id}
            dangerouslySetInnerHTML={{ __html: getHighlightedText(suggestion.name, initialValue) }}
          />
        ))}
      </ul>
    </div>
  );
};

Autocomplete.propTypes = {
  defaultSuggestion: arrayOf(
    shape({
      id: string,
      name: string,
    }),
  ),
  desktop: bool,
  placeholderValue: string,
  startedId: string,
  startedValue: string,
  onAutocompleteSubmitHandler: func,
  onAutocompleteValueBus: func,
};

Autocomplete.defaultProps = {
  onAutocompleteSubmitHandler: null,
  onAutocompleteValueBus: null,
  defaultSuggestion: [],
  desktop: false,
  placeholderValue: '',
  startedId: '',
  startedValue: '',
};

export default Autocomplete;
