const mergeCurried = (changes) => (current) => ({
  ...current,
  ...changes,
});

const merge = (changes, current) => mergeCurried(changes)(current);

const mergeSelectedFilterCurried = (changes) => (current) => {
  const selectedFilter = merge(changes, current.selected_filter);

  return merge({ selected_filter: selectedFilter }, current);
};

export { merge, mergeCurried, mergeSelectedFilterCurried };
