import { getSwitchFilterLabel } from '../../helpers/switch-filter';

const FACETED_FILTER_IDS = {
  LOCATION: 'location',
  OPERATION: 'operation',
  PROPERTY: 'property',
};

const buildInitialFilter = (facetedFilters, key) => {
  const { id, name, value: selectedOption } = facetedFilters.selected_filter[`${key}`] || {};
  const placeholder = facetedFilters.labels.title[`${key}`];

  return {
    id,
    name,
    options: [],
    selectedOption,
    placeholder,
    handleChange: () => undefined,
  };
};

const buildInitialState = (facetedFilters) => ({
  state: null,
  filters: {
    operation: buildInitialFilter(facetedFilters, FACETED_FILTER_IDS.OPERATION),
    propertyType: buildInitialFilter(facetedFilters, FACETED_FILTER_IDS.PROPERTY),
    location: {
      ...buildInitialFilter(facetedFilters, FACETED_FILTER_IDS.LOCATION),
      placeholder: facetedFilters.labels.autocomplete.label,
    },
    operationSubType: {
      name: getSwitchFilterLabel(facetedFilters),
      selectedOption: facetedFilters.selected_filter.only_new,
      disabled: false,
      handleChange: () => undefined,
    },
  },
});

export default buildInitialState;
