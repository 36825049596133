import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { useSearch } from '../../hooks/context';
import { useStaticProps } from '../context/static-props';
import useLazyCategoryId from '../../hooks/use-lazy-category-id';
import { getPathname } from '../context/filters/helpers';
import types from '../factory/component-types';
import isKeepFiltersEnabled from './helpers/is-keep-filters-enabled';
import navigate from './helpers/navigate';
import useClientFacetedFilters from './hooks/use-client-faceted-filters';
import { sendTrack } from './helpers/track';
import FacetedUIDesktop from './faceted-ui.desktop';
import VisFacetedSearchDesktop from './vis-faceted-search.desktop';
import { LAYOUTS } from '../../constants';

const LAYOUT_TYPES = [LAYOUTS.TYPE_GRID, LAYOUTS.TYPE_STACK];

const isSameType =
  (expected) =>
  ({ type }) =>
    type === expected;

const NewFacetedSearchDesktop = ({
  appliedFilters,
  availableFilters,
  facetedFilters,
  siteId,
  sortingOptions,
  visualId,
}) => {
  const { getCategoryId } = useLazyCategoryId(siteId);
  const { state, filters } = useClientFacetedFilters({ appliedFilters, availableFilters, facetedFilters });

  const getNextPathname = (category) =>
    getPathname({
      appliedFilters: isKeepFiltersEnabled(facetedFilters) ? appliedFilters : [],
      removeOffset: true,
      useExistingFilters: true,
      siteId,
      visualId,
      category,
      sortingOptions,
      ...state,
    });

  const handleSubmit = () => {
    sendTrack(state, facetedFilters.tracks?.search_tracks);

    getCategoryId()
      .then(getNextPathname)
      .then(navigate)
      .catch((err) => {
        throw new Error(err);
      });
  };

  const action = {
    label: facetedFilters.labels.action,
    handleSubmit,
  };

  return <FacetedUIDesktop {...filters} action={action} />;
};

NewFacetedSearchDesktop.propTypes = {
  appliedFilters: arrayOf(shape({})).isRequired,
  availableFilters: arrayOf(shape({})).isRequired,
  facetedFilters: shape({}).isRequired,
  siteId: string.isRequired,
  sortingOptions: arrayOf(shape({})).isRequired,
  visualId: string.isRequired,
};

const FacetedSearchDesktopContainer = () => {
  const {
    new_faceted_search: newFacetedSearch,
    sidebar: { components = [] } = {},
    view_options: { sort: { sorts = [] } = {} } = {},
    layout_options,
  } = useSearch();

  const isAllowedLayout = LAYOUT_TYPES.includes(layout_options?.current);

  const { siteId, visualId } = useStaticProps();
  const { filters: availableFilters = [] } = components.find(isSameType(types.AVAILABLE_FILTERS)) || {};

  if (isAllowedLayout && newFacetedSearch && newFacetedSearch?.faceted_config) {
    return <VisFacetedSearchDesktop />;
  }

  return isAllowedLayout && newFacetedSearch ? (
    <NewFacetedSearchDesktop
      facetedFilters={newFacetedSearch}
      appliedFilters={newFacetedSearch.applied_filters}
      availableFilters={availableFilters}
      sortingOptions={sorts}
      visualId={visualId}
      siteId={siteId}
    />
  ) : null;
};

export default FacetedSearchDesktopContainer;
